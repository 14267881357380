
<template>
  <v-app>
    <cabecera/>
<v-main  style="background: #eef5f9">
  <v-container>
    <v-row>
      <v-col>
        <h1>LA ADQUISICION DE LA ENTRADA ADQUIRIDA POR ESTE CANAL SIGNIFICA LA ACEPTACI&Oacute;N DE LAS SIGUIENTES CONDICIONES:</h1>

        <p id="u151-3"></p>

        <h2>1. CONDICIONES GENERALES</h2>
        <ul class="list0 nls-None" id="u151-37">
          <li id="u151-8">Ticket al portador.</li>
          <li>PONTE SAC se reserva el derecho de admisi&oacute;n&nbsp;</li>
          <li>Los eventos vinculados por este medio son de indole PRIVADO.</li>
          <li id="u151-10">La entradas son vendidas por PONTE SAC (en adelante, PONTE) en car&aacute;cter de agente, por cuenta y orden de la empresa organizadora del evento.</li>
          <li id="u151-12">PONTE no es responsable por el aplazamiento o cancelaci&oacute;n del evento, as&iacute; como por cualquier hecho que ocurra durante la celebraci&oacute;n del mismo. En caso de ser cancelado el evento los cargos por servicio no ser&aacute;n reembolsables.</li>
          <li id="u151-14">PONTE no brinda servicio de custodia respecto de los bienes del cliente, siendo responsabilidad exclusiva del cliente la p&eacute;rdida o sustracci&oacute;n del mismo.</li>
          <li id="u151-16">El artista y/o banda se reservan el derecho de modificar y/o alterar las condiciones en que realizar&aacute;(n) su presentaci&oacute;n musical.</li>
          <li id="u151-18">PONTE no se hace responsable por entradas compradas en puntos de venta no oficiales, de ser falsas no se permitir&aacute; el ingreso al espect&aacute;culo.</li>
          <li id="u151-20">En caso de p&eacute;rdida, robo o deterioro de la entrada, PONTE no asume ninguna responsabilidad ni est&aacute; obligada a expedir un nuevo boleto.</li>
          <li id="u151-22">Verifique la fecha, hora y ubicaci&oacute;n elegida, de ser el caso. No se aceptar&aacute;n cambios, devoluciones o reintegros de las entradas adquiridas.</li>
          <li id="u151-24">Verifique que cumpla las condiciones para la compra de la entrada dispuesta por PONTE porcada evento:
            <ul>
              <li>Hombres: Nacidos en el a&ntilde;o 1995 como edad m&iacute;nima</li>
              <li>Mujeres: Nacidas en el a&ntilde;o 1997 como edad m&iacute;nima.&nbsp;</li>
            </ul>
          </li>
          <li>Verifique con el promotor antes de realizar su compra, el debe aceptar la compra.</li>
          <li>Cada promotor tiene un limite de ventas habilitadas, dependiendo del aforo del evento.&nbsp;</li>
          <li id="u151-26">Esta entrada no podr&aacute; ser utilizada para la reventa o aplicaci&oacute;n comercial ni de promoci&oacute;n alguna sin previa autorizaci&oacute;n por escrito del organizador.</li>
          <li id="u151-28">Para ingresar al evento deber&aacute; portar su entrada completa y un documento de identidad valido.</li>
          <li id="u151-30">Al ingresar al recinto el p&uacute;blico podr&aacute; estar sujeto a un registro efectuado por razones de seguridad; si alguien se negara a ello no le ser&aacute; permitido el ingreso al evento. Esta reservado el derecho de admisi&oacute;n y permanencia.</li>
          <li id="u151-32">El ingreso despu&eacute;s de la hora se&ntilde;alada est&aacute; sujeto a la reglas del local.</li>
          <li id="u151-34">Est&aacute; prohibido filmar o grabar este evento. No se permitir&aacute; el ingreso al recinto de grabadoras, filmadoras, m&aacute;quinas fotogr&aacute;ficas, latas, botellas, armas o cualquier objeto que los organizadores consideren peligroso.</li>
          <li id="u151-36">Si la fecha u hora del evento fuera modificada, la presente entrada ser&aacute; v&aacute;lida para ser utilizada en la fecha u hora definitiva que fije PONTE.</li>
        </ul>


        <h2>2. CONDICIONES ESPECIALES: RECOJO DE TICKET EN OFICINA</h2>


        <ul class="list0 nls-None" id="u151-45">
          <li id="u151-44">Para canjear su confirmaci&oacute;n de compra por el recojo de tickets en Oficina: Ac&eacute;rquese con su resumen de compra y copia del DNI a la oficina de PONTE. Deber&aacute; acercarse el titular de la cuenta o la persona autorizada por este al momento de hacer la compra por la p&aacute;gina web.</li>
        </ul>


        <h2>3. CONDICIONES ESPECIALES: TICKETS ELECTR&Oacute;NICOS CON C&Oacute;DIGO QR</h2>



        <ul class="list0 nls-None" id="u151-59">
          <li id="u151-52">Acercarse directamente al evento.</li>
          <li id="u151-54">PONTE no son responsables por la generaci&oacute;n de copias del ticket electr&oacute;nico una vez enviado a su cuenta de correo electr&oacute;nico.</li>
          <li id="u151-56">El adquirente acepta no divulgar ni compartir el (los) ticket(s) electr&oacute;nicos para el evento con ning&uacute;n tercero, ya que esto podr&iacute;a afectar su ingreso al evento.</li>
          <li id="u151-58">En caso de generarse duplicados del ticket electr&oacute;nico, el servicio de verificaci&oacute;n de tickets s&oacute;lo registrar&aacute; el primero en ingresar al evento, siendo todos los dem&aacute;s considerados copias divulgadas por el cliente.</li>
        </ul>


        <p id="u151-64">Ante Cualquier consulta, escr&iacute;banos a&nbsp;<a class="nonblock" href="mailto:info@ponte.pe">info@ponte.pe</a></p>


      </v-col>
    </v-row>
  </v-container>
</v-main>
<piepagina />
  </v-app>
</template>


<script>

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';

export default {
  name: 'terminos',
  components: {
    cabecera,
    piepagina,

  },
  data: () => ({

  }),
}
</script>
